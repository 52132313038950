<template>
  <div class="home">
    <section class="hero is-dark">
      <div class="hero-body">
        <div class="container">
          <h1 class="title">ALIEDOCS</h1>
          <h2 class="subtitle">
            The Awesome Load-Induced Earth Deformation Online Computation Service
          </h2>
          <!--<div class="button-block">
            <button class="button is-xl is-dark">
              Sign Up to Browse Events
            </button>
          </div>-->
        </div>
      </div>
    </section>
    <EventsList />

    <div class="container" id="form_feedback" style="text-align: center;">
      <a href="https://docs.google.com/forms/d/e/1FAIpQLSfbt1H5uqN-ld4TFWakkefkldsdz-vfx9iLQNCAH-s_DOiF2g/viewform?usp=pp_url" target="_blank">
        <img src="../assets/under_construction.png" alt="logo" height="110" width="110" style="display: inline-block; vertical-align: middle;"> 
        <img src="../assets/form_logo.png" alt="logo" height="110" width="110" style="display: inline-block; vertical-align: middle;"> 
      <i style="display: inline-block; vertical-align: middle;">Help us improve ALIEDOCS (complete form feedback)</i>
      </a>
    </div>

    <CarouselHome />
 
  </div>
  <FooterLogos />
</template>
<script>

import CarouselHome from '../components/CarouselHome.vue';
import EventsList from '../components/EventsList';
import FooterLogos from '../components/FooterLogos';
  
  export default {
    name: 'home',
    components: {
      CarouselHome,
      EventsList,
      FooterLogos
    },
  };

//set main color + header size css
var r = document.querySelector(':root');
r.style.setProperty('--header-color', require('../assets/global.json').headerColor);

</script>
<style lang="scss" scoped>
  .hero {
    text-align: center;
    background-color: var(--header-color);
    background-image:  url(../assets/Alpes_blue.jpg); //url(../assets/dalle_txt.webp);//url("https://parispeaceforum.org/wp-content/uploads/2021/10/NET-ZERO-SPACE-INITIATIVE-1.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 450px;
  }
  .hero-body .title {
    text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.6);
    padding: 40px 0 20px 0;
    font-size: 60px;
  }
  .subtitle {
    text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.7);
    font-size: 30px;
  }
  .button-block {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    position: absolute;
    bottom: -150px;
    .button {
      margin-right: 50px;
      padding-left: 50px;
      padding-right: 50px;
    }
    .welcome {
      width: 400px;
      padding: 10px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .is-xl {
    font-size: 1.7rem;
  }

 
</style>