<template>
  <div class="container">
  <Carousel :wrap-around="true" :autoplay="10000">
    <Slide v-for="slide in slides" :key="slide" >
        <div class="carousel__item">
          <div class="caroussel_item_content">
            <h1 class="title">{{ slide.name }}</h1>
           
            <iframe v-if="slide.youtube!=''" width="540" height="290"
            :src="slide.youtube"
            title="YouTube video player" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen>
            </iframe>
            <p> <span v-html="slide.description"></span></p>
            <p><a v-if="slide.link" :href="slide.link" target="_blank">{{ slide.linkText }}</a></p>
          </div>
        </div>
   
    </Slide>

    <template #addons>
      <Navigation />
      <Pagination />
    </template>
  </Carousel>
  </div>
</template>

<script>
import { Carousel, Navigation, Pagination, Slide } from 'vue3-carousel'


import 'vue3-carousel/dist/carousel.css'

export default {
  name: 'Basic',
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
    
  },
  data() {
      return {
        slide: {},
        slides: [
          {
            id: 1,
            name: 'What is ALIEDOCS?',
            youtube : "",
            description:'ALIEDOCS is a service designed to empower a diverse community in Earth Sciences with the possibility to: <ul> <li>&#x2022; Visualize and compare various hydrology, oceanic and atmospheric loading models</li> <li>&#x2022; Compute, on-demand, and visualize load-induced solid Earth surface displacements and changes in gravity.</li> <br>  ALIEDOCS is developed by <a href="https://www.ipgp.fr/">IPGP</a> and <a href="https://www.ign.fr/">IGN</a>, with the support of <a href="https://cnes.fr/fr">CNES</a>, and hosted by <a href="https://www.poleterresolide.fr/">Form@ter</a> – Data and services for the Solid Earth.',
            linkText: "Detailed overview of ALIEDOCS.",
            link: "assets/pdf/2023_PosterAliedocsJR23_JBarneoud.pdf"
          },
          {
            id: 2,
            name: 'Explore more community loading models',
            youtube : "",
            description:'While ALIEDOCS provides user-friendly tools for on-demand visualization and computation of loading models, we highly recommend exploring the <a href="http://loading.u-strasbg.fr/">EOST Loading Service</a>.  Indeed, we acknowledge the importance of exploring a broad range of models, especially community-recognized ones under the Global Geophysical Fluids Center (GGFC), a service of the International Earth Rotation and Reference Systems Service (IERS).',
            linkText: "",
            link: ""
          },
          {
            id: 3,
            name: 'Tutorial: Visualizing loading models',
            route: 'viewer',
            logo: 'assets/logo_load.png',
            youtube : "https://www.youtube.com/embed/E9TtgU2LaRg?si=awU3cZp6GpWQUQox",
            description:'',
            
          },
          {
            id: 4,
            name: 'Tutorial: On-demand computation of loading-induced deformation',
            youtube : "",
            logo :'assets/logo_deformation.png',
            youtube : 'https://www.youtube.com/embed/3iDXrDNqbsc?si=MwN3_BXPjBNWOKW2',
            route: 'computation',
            description:'',
          },
          
        ],
      };
    },
}
</script>

<style lang="scss" scoped>
.carousel__item {
  height: 400px;
  width: 80%;
  background-image: url(../assets/Alpes_blue_a50.jpg);
  border-radius: 8px;
}

.caroussel_item_content{
  color: black;
  justify-content: center;
  align-items: center;
  margin: 5%;
  
    h1 {
      font-size: 25px;
      margin-top: 20px;
    }

    p {
      text-align: center;
      font-size: 18px;
      margin-top: 20px;
    }

    a {
      color: white;
      text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.6);
      text-decoration: underline;
    }

    a:hover{
      text-shadow: #2b6cce 1px 0 10px;
    }
}

.carousel{
  margin-top: 50px;
  margin-bottom: 50px;
  
}




</style>
  
